<div class="main-bg">
    <div class="ui container">
        <div class="ui stackable doubling grid">
            <div class="ui five wide column"></div>
            <div class="ui six wide column login-container">
                <div class="login-form">
                    <p style="padding-top:10px; color: #333b6f;" class="fw-bold">Введите свою почту, вам придет ссылка для сброса пароля</p>
                    <form (ngSubmit)="onSubmit()" [formGroup]="resetPasswordForm" class="ui form">
                        <div class="field">
                            <input formControlName="email" placeholder="Электронная почта" required>
                        </div>
                        <div *ngIf="email.invalid && (email.dirty || email.touched) && submitted"
                             class="alert alert-danger">
                            <div *ngIf="email.errors['required']" class="ui error message">
                                Заполните электронную почту
                            </div>
                            <div *ngIf="email.errors['email'] || email.value.length === 0" class="ui error message">
                                Неправильный адрес почты
                            </div>
                        </div>
                        <div *ngIf="error" class="ui error message">
                            Произошла ошибка, попробуйте позже
                        </div>
                        <div *ngIf="isSubmit && !error" class="ui green message">
                            Письмо отправлено, проверьте Вашу почту.
                        </div>
                        <button class="ui fluid primary button m20" type="submit">
                            Отправить
                        </button>
                        <br/>
                        <a [routerLink]="['/login']" class="small w-100 m20 d-block" style="color: #3e4887; font-size: 12px;">
                            Вернутся на главную
                        </a>
                    </form>
                </div>
            </div>
            <div class="ui sixteen wide column">
                <zarenta-app-footer [class]="'login-footer'"></zarenta-app-footer>
            </div>
        </div>
    </div>
</div>
