import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'zarenta-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: [
        '../../assets/design/semantic-ui/components/button.min.css',
        './page-not-found.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PageNotFoundComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
