<div class="plot-container">
    <div [style.width]="100 * (value1 / maxValue) + '%'">
        <div class="value1" [@bar]=''>
            {{ value1 | number:'.0-2' }}
        </div>
    </div>
    <div [style.width]="100 * (value2 / maxValue) + '%'">
        <div class="value2" [@bar]=''>
            {{ value2 | number:'.0-2' }}
        </div>
    </div>
</div>