<div class='menu-container' [ngClass]="{'mobile': mobileVersion }">
  <div class='flex-container' [ngClass]="{'mobile': mobileVersion }">
    <div class='profile-section' style='max-width: 200px'>
      <a [routerLink]="'/user/card/' + currentUser.id" class='item-link'>
        <img [src]="currentUser.avatar ? (currentUser.avatar.id | secure) : '../../assets/images/defailt-profile.jpeg'"
             class='ui tiny circular image' id='avatar'>


        <div *ngIf='!mobileVersion' class='user-name shiny'>
          {{ currentUser.name }}
        </div>

      </a>
      <i *ngIf='!mobileVersion' (click)='logout()' class='icon power off' title='Выход'></i>

    </div>
    <a class='menu-item item-link'>
            <span routerLink='/' routerLinkActive='active'>
                <i class='icon icon-moon icon-dashboard fw-bold'></i>
                <span *ngIf='!mobileVersion' class='item-text'>Дэшборд</span>
            </span>
    </a>
    <a class='menu-item item-link' *ngxPermissionsOnly="['OFD.ROLE.ADMIN', 'OFD.ROLE.MALL']"
       routerLinkActive='active' routerLink='/building'>
            <span>
                <i class='icon building outline'></i>
                <span *ngIf='!mobileVersion' class='item-text'>Объекты</span>
            </span>
    </a>
    <a class='menu-item item-link' *ngxPermissionsOnly="['OFD.ROLE.ADMIN','OFD.ROLE.MALL']"
       routerLinkActive='active' routerLink='/client'>
            <span>
                <i class='icon briefcase'></i>
                <span *ngIf='!mobileVersion' class='item-text'>Клиенты</span>
            </span>
    </a>
    <!--        <a class="menu-item item-link" *ngxPermissionsOnly="['OFD.ROLE.ADMIN','OFD.ROLE.PARTNER', 'OFD.ROLE.MALL']"-->
    <!--            routerLinkActive="active disabled" routerLink="/activity">-->
    <!--            <span>-->
    <!--                <i class="tag icon"></i>-->
    <!--                <span *ngIf="!mobileVersion" class="item-text">Виды деятельности</span>-->
    <!--            </span>-->
    <!--        </a>-->
    <a class='menu-item item-link' *ngxPermissionsOnly="['OFD.ROLE.ADMIN','OFD.ROLE.MALL']"
       routerLinkActive='active' routerLink='/room'>
            <span>
                <i class='icon icon-moon icon-plan'></i>
                <span *ngIf='!mobileVersion' class='item-text'>Помещения</span>
            </span>
    </a>
    <a class='menu-item item-link' routerLinkActive='active' routerLink='/kkt'>
            <span>
                <i class='icon icon-moon icon-cashbox'></i>
                <span *ngIf='!mobileVersion' class='item-text'>ККТ</span>
            </span>
    </a>
    <!--        <a class="menu-item item-link" *ngxPermissionsOnly="['OFD.ROLE.ADMIN','OFD.ROLE.PARTNER', 'OFD.ROLE.MALL']"-->
    <!--            routerLinkActive="active disabled" routerLink="/adapter">-->
    <!--            <span>-->
    <!--                <i class="icon icon-moon icon-motion-sensor"></i>-->
    <!--                <span *ngIf="!mobileVersion" class="item-text">Счетчики</span>-->
    <!--            </span>-->
    <!--        </a>-->
    <!--        <a class="menu-item item-link" *ngxPermissionsOnly="['OFD.ROLE.ADMIN']" routerLinkActive="active disabled" routerLink="/ofd">-->
    <!--            <span>-->
    <!--                <i class="icon icon-moon icon-cloud-sync fw-bold"></i>-->
    <!--                <span *ngIf="!mobileVersion" class="item-text">ОФД</span>-->
    <!--            </span>-->
    <!--        </a>-->
    <a class='menu-item item-link' *ngxPermissionsOnly="['OFD.ROLE.ADMIN','OFD.ROLE.MALL']" routerLinkActive='active'
       routerLink='/user'>
            <span>
                <i class='icon user circle outline'></i>
                <span *ngIf='!mobileVersion' class='item-text'>Пользователи</span>
            </span>
    </a>
    <a *ngxPermissionsOnly="['OFD.ROLE.ADMIN','OFD.ROLE.MALL']"
       class='ui dropdown pointing item menu-item item-link' suiDropdown>
      <i class='icon chart pie'></i>
      <span *ngIf='!mobileVersion' class='text'>
                &nbsp;Отчеты <i class='dropdown icon' style='font-size: 18px;'></i>
            </span>
      <div class='menu' suiDropdownMenu>
        <a class='item' routerLink='/report/receipt' routerLinkActive='active disabled'>
          <i class='icon chart money bill alternate outline'></i>
          Выручка
        </a>
        <a class='item' routerLink='/report/detail' routerLinkActive='active disabled'>
          <i class='file alternate outline icon'></i>
          Чеки
        </a>
        <a class='item' routerLink='/report/traffic' routerLinkActive='active disabled'>
          <i class='icon users'></i>
          Трафик
        </a>
        <a class='item' routerLink='/report/traffic-time' routerLinkActive='active disabled'>
          <i class='icon users'></i>
          Трафик по часам
        </a>
        <a class='item' routerLink='/report/report-royalty' routerLinkActive='active disabled'>
          <i class='building icon line'></i>
          Роялти
        </a>
        <a class='item' routerLink='/report/report-revenue-compare' routerLinkActive='active disabled'>
          <i class='tag icon'></i>
          Сравнение выручки
        </a>
        <a class='item' routerLink='/report/report-kkt' routerLinkActive='active disabled'>
          <i class='credit card outline icon'></i>
          Активность ККТ
        </a>
      </div>
    </a>
  </div>
  <div class='copyright' [ngClass]="{'mobile': mobileVersion}">
    <span *ngIf='!mobileVersion'>&copy; ЗаРента 2020</span>
  </div>
</div>
