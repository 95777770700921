import { Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
    selector: 'zarenta-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.less'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'scale(0)',
                }),
                animate(
                    '300ms ease-in-out',
                    style({
                        opacity: 1,
                        transform: 'scale(1)',
                    }),
                ),
            ]),
            transition(':leave', [animate('300ms ease-in-out', style({ opacity: 0, transform: 'scale(0)' }))]),
        ]),
    ],
})
export class MessageComponent implements OnInit {
    constructor() {
    }

    @Input() paragraphs: string[] = ['Ничего не найдено.', 'Попробуйте изменить параметры запроса или фильтры.'];
    @Input() type: 'emptySearchResult' = 'emptySearchResult';

    ngOnInit() {
    }
}
