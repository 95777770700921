import { BaseService } from './base.service';
import { BaseModel } from '../_models/base.model';

export class ActivityService extends BaseService {
    /**
     * Параметры запроса
     * Получение массива объектов
     */
    baseUrl = '/activity';

    async find<T>(options?: any): Promise<[T]> {
        const params = {};
        if (options) {
            Object.entries(options).map(o => {
                params[o[0]] = o[1];
            });
        }
        return await this.http.get<[T]>(
            this.baseUrl,
            {
                params,
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem('zarenta.user.token'),
                },
            }).toPromise();
    }

    /**
     * Получение объекта по идентификатору
     */
    async findById<T>(id: number): Promise<T> {
        return await this.http.get<T>(this.baseUrl + '/' + id, {
            withCredentials: true,
            headers: { Authorization: localStorage.getItem('zarenta.user.token') },
        }).toPromise();
    }

    /**
     * Изменение объекта
     */
    async update<T>(model: BaseModel): Promise<T> {
        return await this.http.put<T>(this.baseUrl + '/' + model.id, model, {
            withCredentials: true,
            headers: { Authorization: localStorage.getItem('zarenta.user.token') },
        }).toPromise();
    }


}
