import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionService } from '../_services/action.service';
import { RouterEventService } from '../_services/router-event.service';

@Component({
    selector: 'zarenta-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
    actions: any;
    events: any;

    checked = localStorage.getItem('zarenta.user.menu') ? localStorage.getItem('zarenta.user.menu') === 'true' : false;

    constructor(private actionService: ActionService, private routerEventService: RouterEventService, private readonly route: ActivatedRoute) {
        this.actionService.createMenuEvent.subscribe(action => {
            this.actions.push(action);
        });
        this.actionService.createActionEvent.subscribe(action => {
            this.events.push(action);
        });
        this.routerEventService.onRouterEvent.subscribe((event) => {
            if (event.urlAfterRedirects !== this.routerEventService.getPrevious()) {
                this.actions = [];
                this.events = [];
            }
        });
    }

    saveMenuState() {
        localStorage.setItem('zarenta.user.menu', this.checked.toString());
    }

    ngOnInit() {
        this.actions = [];
        this.events = [];
    }
}
