import { Observable } from 'rxjs/internal/Observable';
import { CurrentUser, User } from '../_models/user.model';
import { BaseService } from './base.service';

export class UserService extends BaseService {
    baseUrl = '/user';
    private currentUser: CurrentUser;

    /**
     * Проверка аутентификации
     */
    isAuthenticated(): Promise<CurrentUser> {
        return new Promise((resolve, reject) => {
            if (!this.getToken()) {
                return reject('No token found');
            }
            this.http.get(this.baseUrl + '/check', {
                withCredentials: true,
                headers: { 'Authorization': this.getToken() },
            }).subscribe((res: CurrentUser) => {
                if (res['id']) {
                    localStorage.setItem('zarenta.user.json', JSON.stringify(res));
                    return resolve(res);
                }
                this.clearStorage();
                return reject();
            }, e => {
                this.clearStorage();
                return reject(e);
            });
        });
    }

    /**
     * Получение текущего пользователя
     */
    current(): CurrentUser {
        if (!this.currentUser) {
            this.currentUser = JSON.parse(localStorage.getItem('zarenta.user.json'));
        }
        return this.currentUser;
    }

    /**
     * Вход пользователя
     */
    login(user: User): Promise<CurrentUser> {
        return new Promise<CurrentUser>((resolve, reject) => {
            return this.http.post(this.baseUrl + '/login', user, { withCredentials: true }).subscribe(res => {
                localStorage.setItem('zarenta.user.userId', res['id']);
                localStorage.setItem('zarenta.user.json', JSON.stringify(res['user']));
                localStorage.setItem('zarenta.user.token', res['token']);
                this.currentUser = res['user'];
                return resolve(this.currentUser);
            }, err => {
                return reject(err);
            });
        });
    }

    /**
     * Выход
     */
    logout(): void {
        this.clearStorage();
    }

    /**
     * Очистка данных пользователя
     */
    clearStorage() {
        localStorage.removeItem('zarenta.user.userId');
        localStorage.removeItem('zarenta.user.json');
        localStorage.removeItem('zarenta.user.token');
    }

    resetPassword(email): Observable<any> {
        return this.http.post(this.baseUrl + '/reset', { email: email });
    }

    changePassword(password, token): Observable<any> {
        return this.http.post(this.baseUrl + '/change', { password: password, token: token });
    }

    /**
     * Получение токена
     */
    private getToken() {
        return localStorage.getItem('zarenta.user.token');
    }
}
