import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'zarenta-compare-barplot',
    templateUrl: './compare-barplot.component.html',
    styleUrls: ['./compare-barplot.component.less'],
    animations: [
        trigger('bar', [
            transition(':enter', [
                style({ width: 0 }),
                animate(500, style({ width: '100%' })),
            ]),
        ]),
    ],
})
export class CompareBarplotComponent implements OnInit {
    @Input() value1: number;
    @Input() value2: number;

    maxValue: number;

    constructor() {
    }

    ngOnInit() {
        this.maxValue = Math.max(this.value1, this.value2);
    }

}
