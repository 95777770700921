import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { BaseModel } from '../_models/base.model';

/**
 * Базовый сервис
 * Определяет стандартные методы API
 */
@Injectable()
export class BaseService {

    /**
     * Базовый url сервиса
     */
    protected baseUrl: string;

    /**
     * Инжектим HTTP клиент
     */
    constructor(protected http: HttpClient, protected router: Router) {
    }

    get(url, options?) {

        const data: any = {
            withCredentials: true,
            headers: { Authorization: localStorage.getItem('zarenta.user.token') },
        };

        if (options) {
            data.params = this.clearOptions(options);
        }

        return this.http.get(this.baseUrl + '/' + url, data);
    }

    /**
     * Создание объекта
     */
    async create<T>(model: BaseModel): Promise<T> {
        return await this.http.post<T>(this.baseUrl, model, {
            withCredentials: true,
            headers: { Authorization: localStorage.getItem('zarenta.user.token') },
        }).toPromise();
    }

    /**
     * Удаление объекта
     */
    async deleteById<T>(id: number): Promise<T> {
        return await this.http.delete<T>(this.baseUrl + '/' + id, {
            withCredentials: true,
            headers: { Authorization: localStorage.getItem('zarenta.user.token') },
        }).toPromise();
    }

    /**
     * Параметры запроса
     * Получение массива объектов
     */
    async find<T>(options?: any): Promise<[T]> {
        let params = {};
        if (options) {
            params = this.clearOptions(options) as any;
        }
        return await this.http.get<[T]>(
            this.baseUrl,
            {
                params,
                withCredentials: true,
                headers: {
                    Authorization: localStorage.getItem('zarenta.user.token'),
                },
            }).toPromise();
    }

    /**
     * Получение объекта по идентификатору
     */
    async findById<T>(id: number): Promise<T> {
        return await this.http.get<T>(this.baseUrl + '/' + id, {
            withCredentials: true,
            headers: { Authorization: localStorage.getItem('zarenta.user.token') },
        }).toPromise();
    }

    /**
     * Изменение объекта
     */
    async update<T>(model: BaseModel): Promise<T> {
        return await this.http.put<T>(this.baseUrl + '/' + model.id, model, {
            withCredentials: true,
            headers: { Authorization: localStorage.getItem('zarenta.user.token') },
        }).toPromise();
    }

    public clearOptions<T>(queries: T): T {
        const options = {} as T;
        Object.keys(queries).map(key => {
            if (!queries[key] || (Array.isArray(queries[key]) && queries[key].length === 0)) {
                return;
            }
            options[key] = queries[key];
        });
        return options;
    }
}
