import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { OnlineComponent } from 'src/app/dashboard/online/online.component';
import { LoggedInUserGuard } from './_guards/logged-in-user.guard';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ReportSingledayComponent } from './dashboard/report-singleday/report-singleday.component';

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        canActivate: [LoggedInUserGuard],
        children: [{
            path: '',
            canActivate: [NgxPermissionsGuard],
            data: {
                title: 'ЗаРента | Дэшборд',
                breadcrumb: 'Дэшборд',
                permissions: {
                    only: ['OFD.ROLE.ADMIN', 'OFD.ROLE.MALL'],
                },
            },
            component: ReportSingledayComponent,
            runGuardsAndResolvers: 'always',
        }, {
            path: 'building',
            canActivate: [NgxPermissionsGuard],
            data: {
                title: 'ЗаРента | Объекты',
                breadcrumb: 'Объекты',
                permissions: {
                    only: ['OFD.ROLE.ADMIN', 'OFD.ROLE.MALL'],
                    redirect: '/',
                },
            },
            loadChildren: './building/building.module#BuildingModule',
            runGuardsAndResolvers: 'always',
        }, {
            path: 'client',
            canActivate: [NgxPermissionsGuard],
            data: {
                title: 'ЗаРента | Клиенты',
                breadcrumb: 'Клиенты',
                permissions: {
                    only: ['OFD.ROLE.ADMIN', 'OFD.ROLE.MALL'],
                    redirect: '/',
                },
            },
            loadChildren: './client/client.module#ClientModule',
            runGuardsAndResolvers: 'always',
        },
        {
            path: 'activity',
            canActivate: [NgxPermissionsGuard],
            data: {
                title: 'ЗаРента | Виды деятельности',
                breadcrumb: 'Виды деятельности',
                permissions: {
                    only: ['OFD.ROLE.ADMIN', 'OFD.ROLE.MALL'],
                    redirect: '/',
                },
            },
            loadChildren: './activity/activity.module#ActivityModule',
            runGuardsAndResolvers: 'always',
        },
        {
            path: 'floor',
            data: {
                title: 'ЗаРента | Этажи',
                breadcrumb: 'Этажи',
            },
            loadChildren: './floor/floor.module#FloorModule',
            runGuardsAndResolvers: 'always',
        }, {
            path: 'zone',
            data: {
                title: 'ЗаРента | Зоны помещений',
                breadcrumb: 'Зоны помещений',
            },
            loadChildren: './zone/zone.module#ZoneModule',
            runGuardsAndResolvers: 'always',
        }, {
            path: 'room',
            canActivate: [NgxPermissionsGuard],
            data: {
                title: 'ЗаРента | Помещения',
                breadcrumb: 'Помещения',
                permissions: {
                    except: ['OFD.ROLE.CLIENT'],
                    redirect: '/',
                },
            },
            loadChildren: './room/room.module#RoomModule',
            runGuardsAndResolvers: 'always',
        }, {
            path: 'kkt',
            canActivate: [NgxPermissionsGuard],
            data: {
                title: 'ЗаРента | ККТ',
                breadcrumb: 'ККТ',
            },
            loadChildren: './kkt/kkt.module#KktModule',
            runGuardsAndResolvers: 'always',
        }, {
            path: 'adapter',
            canActivate: [NgxPermissionsGuard],
            data: {
                title: 'ЗаРента | Датчики',
                breadcrumb: 'Датчики',
                permissions: {
                    except: ['OFD.ROLE.ADMIN', 'OFD.ROLE.MALL'],
                    redirect: '/',
                },
            },
            loadChildren: './adapter/adapter.module#AdapterModule',
            runGuardsAndResolvers: 'always',
        }, {
            path: 'ofd',
            canActivate: [NgxPermissionsGuard],
            data: {
                title: 'ЗаРента | ОФД',
                breadcrumb: 'ОФД',
                permissions: {
                    only: ['OFD.ROLE.ADMIN'],
                    redirect: '/',
                },
            },
            loadChildren: './ofd/ofd.module#OfdModule',
            runGuardsAndResolvers: 'always',
        }, {
            path: 'user',
            canActivate: [NgxPermissionsGuard],
            data: {
                title: 'ЗаРента | Пользователи',
                breadcrumb: 'Пользователи',
                permissions: {
                    only: ['OFD.ROLE.ADMIN', 'OFD.ROLE.MALL'],
                    redirect: '/',
                },
            },
            loadChildren: './user/user.module#UserModule',
            runGuardsAndResolvers: 'always',
        }, {
            path: 'report',
            canActivate: [NgxPermissionsGuard],
            data: {
                title: 'ЗаРента | Отчеты',
                breadcrumb: 'Отчеты',
            },
            loadChildren: './report/report.module#ReportModule',
            runGuardsAndResolvers: 'always',
        }],
    }, {
        path: 'login',
        component: LoginComponent,
        data: {
            title: 'ЗаРента | Вход',
        },
    }, {
        path: 'reset',
        component: ResetPasswordComponent,
        data: {
            title: 'ЗаРента | Сброс пароля',
        },
    },
    {
        path: 'reset-password',
        component: ChangePasswordComponent,
        data: {
            title: 'ЗаРента | Новый пароль',
        },
    }, {
        path: '**',
        component: PageNotFoundComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
    ],
    exports: [
        RouterModule,
    ],
})
export class AppRoutingModule {
}
