import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of, Subscriber } from 'rxjs';
import { UserService } from './user.service';

export interface FileInterface {
    path: string;
    name: string;
    size: number;
    type: string;
}

/**
 * Отвечает за загрузку и выгрузку файлов
 * @class FileService
 */
@Injectable()
export class FileService {

    /**
     * Загруженные файлы
     * Работает глобально и при переходе по роутам
     * При рефреше не работает
     * @type {{}}
     */
    private static loaded = {};

    /**
     * EventEmitter
     * При первой загрузке по HTTP создается обработчик, при последующих запросах ожидается выполнение первого звгрузчика
     * @type {{}}
     */
    private static files = {};

    protected baseUrl = '/file';

    /**
     * Инжектим HTTP клиент
     * @param {HttpClient} http
     * @param userService
     */
    constructor(protected http: HttpClient, private userService: UserService) {
    }

    /**
     * Загрузка файла из API
     * @param path
     * @param name
     * @returns {Observable<>}
     */
    download(id): Observable<any> {
        if (!id) {
            return of(false);
        }
        let objectUrl: string = null;
        if (FileService.loaded[id]) {
            return new Observable((observer: Subscriber<any>) => {
                if (FileService.files[id]) {
                    observer.next(URL.createObjectURL(FileService.files[id]));
                } else {
                    FileService.loaded[id].subscribe(r => {
                        objectUrl = URL.createObjectURL(r);
                        observer.next(objectUrl);
                    });
                }
            });
        }
        FileService.loaded[id] = new EventEmitter();
        return new Observable((observer: Subscriber<any>) => {
            this.http.get(this.baseUrl + '/' + id, {
                withCredentials: true,
                headers: { 'Authorization': localStorage.getItem('zarenta.user.token') },
                responseType: 'blob'
            }).subscribe(r => {
                objectUrl = URL.createObjectURL(r);
                FileService.loaded[id].emit(r);
                FileService.files[id] = r;
                observer.next(objectUrl);
            });
            return () => {
                if (objectUrl) {
                    URL.revokeObjectURL(objectUrl);
                    objectUrl = null;
                }
            };
        });
    }

    /**
     * Загрузка файла на сервер
     * @param files
     */
    upload(files: FileList): Promise<FileInterface[]> {
        const promises = [];
        if (files) {
            for (let iCount = 0; iCount < files.length; iCount++) {
                promises.push(this.getFile(files[iCount]));
            }
        }
        return Promise.all(promises);
    }

    getFile(file: File) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return new Promise((resolve, reject) => {
            return this.http.post(this.baseUrl, formData, { headers: { 'Authorization': localStorage.getItem('zarenta.user.token') } }).toPromise().then(data => {
                return resolve(data);
            }).catch(error => {
                return reject(error);
            });
        });
    }
}
