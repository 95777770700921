<div>
    <svg @fadeIn *ngIf="type==='emptySearchResult'" class='svg-icon' version="1.1" viewBox="0 0 32 32" width="320px"
         xmlns="http://www.w3.org/2000/svg"
    >
        <path
                d="M17.308843,20.0159497 C16.5390075,20.6317636 15.5625069,21 14.5,21 C12.0147185,21 10,18.9852815 10,16.5 C10,14.0147185 12.0147185,12 14.5,12 C16.9852815,12 19,14.0147185 19,16.5 C19,17.5625069 18.6317636,18.5390075 18.0159497,19.308843 L23.363961,24.6568542 L22.6568542,25.363961 L17.308843,20.0159497 L17.308843,20.0159497 Z M17,9 L15,5 L4.00276013,5 C2.89666625,5 2,5.88967395 2,6.991155 L2,25.008845 C2,26.1085295 2.89971268,27 3.99328744,27 L29.0067126,27 C30.1075748,27 31,26.1073772 31,25.0049107 L31,10.9950893 C31,9.8932319 30.1029399,9 28.9941413,9 L17,9 L17,9 Z M14.5,20 C16.4329967,20 18,18.4329967 18,16.5 C18,14.5670033 16.4329967,13 14.5,13 C12.5670033,13 11,14.5670033 11,16.5 C11,18.4329967 12.5670033,20 14.5,20 L14.5,20 Z"
                id="folder-search"/>
    </svg>
    <div *ngFor="let text of paragraphs">
        <p @fadeIn [innerText]="text"></p>
    </div>
</div>
