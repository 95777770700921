import { Component, EventEmitter, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Room } from '../../_models/room.model';
import { RoomService } from '../../_services/room.service';
import { BaseDropdownComponent } from '../../common/dropdown/base-dropdown.component';

@Component({
  selector: 'zarenta-room-multi-dropdown',
  template: `
    <ng-template #itemOptionTemplate let-option>
      <div class='mininame'>
        <span [innerText]='option.name'></span>
      </div>
      <div class='miniprice'>
        <span [innerText]="option.floor && option.floor.mall ? option.floor.mall.name : ''"></span>
      </div>
      <div class='minitext'></div>
    </ng-template>
    <sui-multi-select class='fluid selection mx-auto'
                      [placeholder]='placeholder'
                      [isSearchable]='true'
                      [isDisabled]='false'
                      [(ngModel)]='selected'
                      [ngModelOptions]='{name: name}'
                      (ngModelChange)='save()'
                      [options]='array'
                      [optionTemplate]='itemOptionTemplate'
                      labelField='name'
                      #selectItems>
      <sui-select-option *ngFor='let item of selectItems.filteredOptions'
                         [value]='item'>
      </sui-select-option>
    </sui-multi-select>
  `,
  styleUrls: [
    '../../../assets/design/semantic-ui/components/label.min.css',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RoomMultiDropdownComponent extends BaseDropdownComponent<Room[], Room[]> implements OnInit {
  @ViewChild('selectItems', { static: true }) selectItems;
  name = 'room';
  placeholder = 'Помещение';

  @Input() event: EventEmitter<Room[]> = new EventEmitter<Room[]>();

  @Input()
  selectRooms: EventEmitter<number[]>;

  constructor(private roomService: RoomService) {
    super();
  }

  ngOnInit() {
    this.load().catch(console.error);

    if (this.selectRooms) {
      this.selectRooms.subscribe(rooms => {
        if (this.array) {
          this.selectItems.selectedOptions = this.array.filter(room => rooms.includes(room.id));
        }
      });
    }
  }

  async load() {
    this.array = await this.roomService.find<Room>();
  }

  save() {
    this.event.emit(this.selected);
    this.action.emit(this.selected);
  }
}
