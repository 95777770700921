import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'zarenta-app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./app-footer.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class AppFooterComponent implements OnInit {

    @Input()
    class: string;

    constructor() {
    }

    ngOnInit() {
    }

}
