import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable()
export class RouterEventService {
    onRouterEvent: EventEmitter<any> = new EventEmitter();
    private previousUrl: string;
    private currentUrl: string;

    constructor(private router: Router) {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.urlAfterRedirects;
            }
            this.onRouterEvent.emit(event);
        });
    }

    public getPrevious() {
        return this.previousUrl;
    }
}
