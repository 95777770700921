import * as moment from 'moment';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { Room } from 'src/app/_models/room.model';
import { Client } from 'src/app/_models/client.model';
import { ReportService } from 'src/app/_services/report.service';

@Component({
    selector: 'zarenta-report-singleday',
    templateUrl: './report-singleday.component.html',
    styleUrls: [
        '../../../assets/design/semantic-ui/components/form.min.css',
        '../../../assets/design/semantic-ui/components/input.min.css',
        '../../../assets/design/semantic-ui/components/loader.min.css',
        './report-singleday.component.less']
})
export class ReportSingledayComponent implements OnInit {
    date: Date = new Date();
    dayDate: string = moment().format('DD.MM.YYYY');
    weekDate: string = moment().subtract(7, 'd').format('DD.MM.YYYY');
    state = {
        initialLoad: true,
        loaded: true,
    };

    options = {
        date: this.dayDate,
        count: 50,
        sorting: 'client.name:string',
        mallIds: [],
        roomIds: [],
        clientIds: [],
    };

    columnInfo = [
        { innerText: 'Клиент', format: 'client.name:string' },
        { innerText: 'Выручка, руб', format: 'day.revenue:number' },
        { innerText: 'Кол-во чеков', format: 'day.checkCount:number' },
        { innerText: 'Средний чек, руб.', format: 'day.avgCheck:number' },
    ];

    data: any[] = [];

    buildingFilter: EventEmitter<any> = new EventEmitter<any>();
    roomFilter: EventEmitter<Room[]> = new EventEmitter<Room[]>();
    clientFilter: EventEmitter<Client[]> = new EventEmitter<Client[]>();

    constructor(private reportService: ReportService) {
    }

    ngOnInit() {
        this.buildingFilter.subscribe(mallIds => this.setFilter(mallIds, 'mallIds'));
        this.roomFilter.subscribe(roomIds => this.setFilter(roomIds, 'roomIds'));
        this.clientFilter.subscribe(clientIds => this.setFilter(clientIds, 'clientIds'));
    }

    changeDate(date: Date) {
        this.date = date;
        this.options.date = moment(date).format('DD.MM.YYYY');
        this.dayDate = moment(date).format('DD.MM.YYYY');
        this.weekDate = moment(date).subtract(7, 'd').format('DD.MM.YYYY');
    }

    getReport() {
        this.state.initialLoad = false;
        this.state.loaded = false;

        this.reportService.revenueReceipt(this.options).subscribe((res: any) => {
            this.data = res.data.items;
            this.state.loaded = true;
        });
    }

    setFilter(chosenArr: any[], chosenName: string) {
        if (chosenArr && chosenName) {
            this.options[chosenName] = chosenArr.map(item => item.id);
        }
    }

    applySortClasses(name: string) {
        let result = '';
        if ([0, 1].includes(this.options.sorting.indexOf(name))) {
            result += 'sorted';
            if (this.options.sorting[0] === '-') {
                result += ' descending';
            } else {
                result += ' ascending';
            }
        }
        return result;
    }

    changeSort(type = 'date:formattedDate') {
        if (type === this.options.sorting) {
            if (type[0] === '-') {
                type = type.substring(1);
            } else {
                type = '-' + type;
            }
        }
        this.options.sorting = type;
        this.getReport();
    }
}
