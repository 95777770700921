<div class="ui stackable fluid secondary menu">
    <a class="item" *ngFor="let action of actions; last as isLast"
         [ngClass]="{'mr-10px': !isLast && actions && actions.length > 0}">
        <button [class]="'ui button ' + action.class" [routerLink]=[action.link]>
            <span [innerHTML]="action.name" class="fs-11 font-montserrat"></span>
        </button>
    </a>
    <a class="item" *ngFor="let event of events; last as isLast; first as isFirst"
         [ngClass]="{'mr-10px': !isLast && events && events.length > 0, 'ml-10px': isFirst}">
        <button (click)="event.event.emit()" [class]="'ui button ' + event.class">
            <span [innerHTML]="event.name" class="fs-11 font-montserrat"></span>
        </button>
    </a>
</div>
