<ng-template #itemOptionTemplate let-option>
    <div class="mininame">
        <span [innerText]="option.name"></span>
    </div>
    <div class="miniprice">
        <span [innerText]="option.address ? option.address : ''"></span>
    </div>
    <div class="minitext"></div>
</ng-template>
<sui-multi-select class="fluid selection mx-auto"
                  [placeholder]="placeholder"
                  [isSearchable]="true"
                  [isDisabled]="false"
                  [(ngModel)]="selected"
                  [ngModelOptions]="{name: name}"
                  (ngModelChange)="save()"
                  [options]="array"
                  [optionTemplate]="itemOptionTemplate"
                  labelField="name"
                  #selectItems>
    <sui-select-option *ngFor="let item of selectItems.filteredOptions | orderByColumn:'name:string'"
                       [value]="item">
    </sui-select-option>
</sui-multi-select>
