<ng-progress [color]="'#E38E3D'" [spinner]="false"></ng-progress>


<input id="menu-open-checkbox" type="checkbox" [(ngModel)]="checked" (ngModelChange)="saveMenuState()">
<div class="burger-icon">
    <span class="burger"></span>
    <span class="burger"></span>
    <span class="burger"></span>
</div>

<div class="flex-columns">
    <div class="column-1" *ngIf="!checked" [ngClass]="{mobile: !checked}">
        <zarenta-app-menu [mobileVersion]="!checked"></zarenta-app-menu>
    </div>
    <div class="column-1" *ngIf="checked">
        <zarenta-app-menu [mobileVersion]="!checked"></zarenta-app-menu>
    </div>

    <div class="column-2">
        <div class="crumbs">
            <div class="ui stackable grid align-items-center">
                <div class="ui eight wide column">
                    <zarenta-breadcrumb></zarenta-breadcrumb>
                </div>
                <div class="ui eight wide column text-right">
                    <zarenta-action [actions]="actions" [events]="events"></zarenta-action>
                </div>
            </div>
        </div>
        <div class="ui doubling grid" style="margin: 0">
            <div class="ui sixteen wide column m-0 p-0">
                <div class="main container-fluid">
                    <div class="ui stackable grid" style="margin: 0;">
                        <div class="ui sixteen wide column" style="min-height: calc(98vh - 80px)">
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                    <zarenta-app-footer></zarenta-app-footer>
                </div>
            </div>
        </div>
    </div>
</div>
