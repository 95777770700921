import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService extends BaseService {
  dateFrom: string = moment().startOf('day').format('DD.MM.YYYY');
  baseUrl = '/report';

  receipt = (options: {
    from: string;
    to: string;
    mallIds?: number[];
    floorIds?: number[];
    zoneIds?: number[];
    roomIds?: number[];
    clientIds?: number[];
  }) => {
    return this.get('receipt', options);
  };

  traffic = (options: {
    from: string;
    to: string;
    mallIds?: number[];
    floorIds?: number[];
    zoneIds?: number[];
    roomIds?: number[];
    clientIds?: number[];
  }) => {
    return this.get('traffic', options);
  };

  trafficTime = (options: {
    from: string;
    to: string;
    mallIds?: number[];
    floorIds?: number[];
    zoneIds?: number[];
    roomIds?: number[];
  }) => {
    return this.get('traffic-time', options);
  };

  detail = (options: {
    from: string;
    to: string;
    clientIds?: number[];
    operatorIds?: number[];
    operationTypeIds?: number[];
  }) => {
    return this.get('detail', options);
  };

  royalty = (options: {
    from: string;
    to: string;
    mallIds?: number[];
    floorIds?: number[];
    zoneIds?: number[];
    roomIds?: number[];
    clientIds?: number[];
  }) => {
    const params = this.clearOptions(options) as any;
    return this.http.get(this.baseUrl + '/royalty', {
      params,
      withCredentials: true,
      headers: { Authorization: localStorage.getItem('zarenta.user.token') },
    });
  };

  revenueCompare = (options: {
    from: string;
    to: string;
    activityIds: number[];
    mallIds?: number[];
    floorIds?: number[];
    zoneIds?: number[];
    roomIds?: number[];
    clientIds?: number[];
  }) => {
    return this.get('revenue-compare', options);
  };

  // средний чек
  averageCheck = (options: {
    from: string;
    to: string;
    mallIds?: number[];
    floorIds?: number[];
    zoneIds?: number[];
    roomIds?: number[];
    clientIds?: number[];
  }) => {
    return this.http.post(
      this.baseUrl + '/traffic',
      { options },
      {
        withCredentials: true,
        headers: { Authorization: localStorage.getItem('zarenta.user.token') },
      },
    );
  };

  trafficRavenue = (options: {
    from: string;
    to: string;
    mallIds?: number[];
    floorIds?: number[];
    zoneIds?: number[];
    roomIds?: number[];
    clientIds?: number[];
  }) => {
    return this.http.post(
      this.baseUrl + '/revenue-and-traffic',
      { options },
      {
        withCredentials: true,
        headers: { Authorization: localStorage.getItem('zarenta.user.token') },
      },
    );
  };

  revenueReceipt = (options: {}) => {
    return this.get('revenue-receipt', options);
  };

  kktActivity = (options: {
    from: string;
    to: string;
    mallIds?: number[];
    clientIds?: number[];
    operatorIds?: number[];
  }) => {
    return this.get('kkt', options);
  };
}
