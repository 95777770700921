import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import * as moment from 'moment';
import { SuiModule } from 'ng2-semantic-ui';
import { NgxPermissionsModule } from 'ngx-permissions';
import { OnlineComponent } from 'src/app/dashboard/online/online.component';
import { LoggedInUserGuard } from './_guards/logged-in-user.guard';
import { ApiHttpInterceptor } from './_interceptors/api-http-interceptor';
import { AccessTokenService } from './_services/access-token.service';
import { ActionService } from './_services/action.service';
import { ActivityService } from './_services/activity.service';
import { AdapterService } from './_services/adapter.service';
import { BaseService } from './_services/base.service';
import { ClientService } from './_services/client.service';
import { FileService } from './_services/file.service';
import { FloorService } from './_services/floor.service';
import { KktService } from './_services/kkt.service';
import { MallService } from './_services/mall.service';
import { OperatorService } from './_services/operator.service';
import { ReceiptService } from './_services/receipt.service';
import { ReportService } from './_services/report.service';
import { RoleService } from './_services/role.service';
import { RoomService } from './_services/room.service';
import { RouterEventService } from './_services/router-event.service';
import { TrafficService } from './_services/traffic.service';
import { UserService } from './_services/user.service';
import { ZoneService } from './_services/zone.service';
import { ActionComponent } from './action/action.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompareBarplotComponent } from './dashboard/report-singleday/compare-barplot/compare-barplot.component';
import { ReportSingledayComponent } from './dashboard/report-singleday/report-singleday.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SharedModule } from './shared/shared.module';

registerLocaleData(localeRu, 'ru');
moment.locale('ru');

@NgModule({
  declarations: [
    ActionComponent,
    AppComponent,
    AppFooterComponent,
    AppMenuComponent,
    BreadcrumbComponent,
    ChangePasswordComponent,
    DashboardComponent,
    LoginComponent,
    PageNotFoundComponent,
    ResetPasswordComponent,
    OnlineComponent,
    ReportSingledayComponent,
    CompareBarplotComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    NgProgressModule,
    NgProgressHttpModule,
    ReactiveFormsModule,
    SharedModule,
    SuiModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    AccessTokenService,
    ActionService,
    AdapterService,
    BaseService,
    ClientService,
    FileService,
    FloorService,
    KktService,
    LoggedInUserGuard,
    MallService,
    OperatorService,
    ReceiptService,
    RoleService,
    RoomService,
    RouterEventService,
    TrafficService,
    UserService,
    ZoneService,
    ActivityService,
    ReportService,
    {
      provide: LOCALE_ID,
      useValue: 'ru-RU',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiHttpInterceptor,
      multi: true,
    },
  ],
  schemas: [NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
