<div class="main-bg">
    <div class="ui container">
        <div class="ui stackable grid">
            <div class="ui nine wide column">
                <div class="login-logo">
                    ЗаРента
                </div>
                <span class="login-slogan">&nbsp;Агрегатор данных ОФД</span>
            </div>
            <div class="ui six wide column login-container">
                <div class="login-form">
                    <div class="login-fw-bold">Вход в личный кабинет</div>
                    <br/>
                    <form (ngSubmit)="onSubmit()" [formGroup]="reactiveLoginForm" class="ui form">
                        <div class="field">
                            <!--<label class="text-uppercase semi-bold">Email</label>-->
                            <input autofocus class="login-input" formControlName="email" name="email"
                                   placeholder="Электронная почта" required>
                            <div *ngIf="email.invalid && (email.dirty || email.touched) && submitted"
                                 class="alert alert-danger">
                                <div *ngIf="email.errors['required']">
                                    Email обязателен
                                </div>
                                <div *ngIf="email.errors['email']">
                                    Неверный email адрес
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <!--<label class="text-uppercase semi-bold">Пароль</label>-->
                            <input class="form-control login-input" formControlName="password" name="password"
                                   placeholder="Пароль" required type="password">
                            <div *ngIf="password.invalid && (password.dirty || password.touched) && submitted"
                                 class="alert alert-danger">
                                <div *ngIf="password.errors['required']">
                                    Введите пароль
                                </div>
                            </div>
                        </div>
                        <div *ngIf="error" class="ui negative message">
                            <div class="header">{{error.code}}</div>
                            <div class="content">
                                {{error.name}}
                            </div>
                        </div>
                        <button class="ui fluid primary button m20 login-button" type="submit" [ngClass]="{'loading': loading}">
                            Вход
                        </button>
                        <br/>
                        <a [routerLink]="['/reset']" class="small w-100 m20 d-block" style="color: #3e4887; font-size: 12px;">
                            Забыли пароль?
                        </a>
                    </form>
                </div>
            </div>
            <div class="ui sixteen wide column">
                <zarenta-app-footer [class]="'login-footer'"></zarenta-app-footer>
            </div>
        </div>

    </div>
</div>
