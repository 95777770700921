import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserService } from '../_services/user.service';

@Injectable()
export class LoggedInUserGuard implements CanActivate {

    userService: UserService;

    constructor(private router: Router, userService: UserService, private permissionsService: NgxPermissionsService) {
        this.userService = userService;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.userService.isAuthenticated().then(res => {
            const perm = [];
            res.roles.map(role => {
                perm.push(role);
            });
            this.permissionsService.loadPermissions(perm);
            return true;
        }).catch(() => {
            this.router.navigate(['/login']).catch(err => {
                console.error(err);
            });
            return false;
        });
    }
}
