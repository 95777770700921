import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'zarenta-report-search-button',
    templateUrl: './report-search-button.component.html',
    styleUrls: ['./report-search-button.component.css'],
})
export class ReportSearchButtonComponent implements OnInit {
    constructor() {}
    @Input() state: { initialLoad: boolean; loaded: boolean } = { initialLoad: false, loaded: false };

    ngOnInit() {}
}
