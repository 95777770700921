<div class="ui stackable grid">
    <div class="ui sixteen wide column">
        <div class="ui form w-100">
            <div class="ui five stackable fields">
                <div class="field">
                    <label class="fw-bold">День</label>
                    <div class="ui left icon input">
                        <i class="calendar icon"></i>
                        <input suiDatepicker [ngModel]="date" (ngModelChange)="changeDate($event)" pickerMode="date"
                               [pickerUseNativeOnMobile]="false">
                    </div>
                </div>
                <div class="field">
                    <label class="fw-bold">Здание</label>
                    <zarenta-building-multi-dropdown [event]="buildingFilter"></zarenta-building-multi-dropdown>
                </div>
                <div class="field">
                    <label class="fw-bold">Помещение</label>
                    <zarenta-room-multi-dropdown [event]="roomFilter"></zarenta-room-multi-dropdown>
                </div>
                <div class="field">
                    <label class="fw-bold">Клиент</label>
                    <zarenta-client-multi-dropdown [event]="clientFilter"></zarenta-client-multi-dropdown>
                </div>
                <div class="field">
                    <zarenta-report-search-button [state]="state" (click)="getReport()">
                    </zarenta-report-search-button>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!state.initialLoad && state.loaded && !data.length" class="ui center aligned sixteen wide column">
        <zarenta-message></zarenta-message>
    </div>
    <sui-dimmer [(isDimmed)]="!state.loaded && state.initialLoad" [isClickable]="false" class="inverted">
        <div class="ui text loader">Загрузка...</div>
    </sui-dimmer>

    <div class="legend" *ngIf="data.length">
        <div class="item">
            <div class="day-color"></div>
            <div>&nbsp; – {{ dayDate }}</div>
        </div>
        <div class="item">
            <div class="week-color"></div>
            <div>&nbsp; – {{ weekDate }}</div>
        </div>
    </div>

    <div *ngIf="data.length" class="ui sixteen wide column">
        <table class="ui selectable sortable very basic table">
            <thead>
            <tr>
                <th *ngFor="let column of columnInfo"
                    (click)="changeSort(column.format)"
                    [ngClass]="applySortClasses(column.format)"
                >
                    {{ column.innerText }}
                    <i *ngIf="options.sorting !== ('-' + column.format) && options.sorting !== column.format"
                       class="icon sort"></i>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let client of data">
                <td>
                    <a [routerLink]="['/', 'client', 'card', client.client.id ]" target="_blank">
                        {{ client.client.name }}
                    </a>
                    <div class="mt-8px greytext">
                        {{client.mall.name}}, {{client.room.name}}
                    </div>
                </td>
                <td>
                    <zarenta-compare-barplot [value1]="client.day.revenue" [value2]="client.week.revenue">
                    </zarenta-compare-barplot>
                </td>
                <td>
                    <zarenta-compare-barplot [value1]="client.day.checkCount" [value2]="client.week.checkCount">
                    </zarenta-compare-barplot>
                </td>
                <td>
                    <zarenta-compare-barplot [value1]="client.day.avgCheck" [value2]="client.week.avgCheck">
                    </zarenta-compare-barplot>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
