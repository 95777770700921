import {Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {SuiSelect} from 'ng2-semantic-ui/dist';

@Component({
    selector: 'zarenta-base-dropdown',
    template: '',
})
export class BaseDropdownComponent<T, S> {

    @ViewChild('selectItem', {static: true})
    selectItem: SuiSelect<any, any>;

    @Output() public action: EventEmitter<S> = new EventEmitter<S>();

    public array: T;
    public selected: S;

    constructor() {
    }

    @Input()
    set select(object) {
        this.selected = object || null;

        if (this.selectItem) {
            this.selectItem.selectedOption = object || null;
        }
    }

    updateSelect() {
        if (this.selected && (<any>this.selected).id && this.array) {
            this.selected = (<any>this.array).find(o => o.id === (<any>this.selected).id);
            this.selectItem.selectedOption = this.selected;
        }
    }

    save() {
        this.action.emit(this.selected);
    }
}
