import {Component, EventEmitter, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MallService} from '../../_services/mall.service';
import {Mall} from '../../_models/mall.model';
import {BaseDropdownComponent} from '../../common/dropdown/base-dropdown.component';

@Component({
    selector: 'zarenta-building-multi-dropdown',
    templateUrl: '../../common/dropdown/multi/base-multi-dropdown.component.html',
    styleUrls: [
        '../../../assets/design/semantic-ui/components/label.min.css'
    ],
    encapsulation: ViewEncapsulation.None
})
export class BuildingMultiDropdownComponent extends BaseDropdownComponent<Mall[], Mall[]> implements OnInit {
    @ViewChild('selectItems', {static: true}) selectItems;

    name = 'building';

    placeholder = 'ТЦ';

    @Input()
    public event: EventEmitter<any> = new EventEmitter<any>();

    constructor(private buildingService: MallService) {
        super();
    }

    ngOnInit() {
        this.load().catch(console.error);
    }

    async load() {
        this.array = await this.buildingService.find<Mall>();
    }

    save() {
        this.event.emit(this.selected);
        this.action.emit(this.selected);
    }
}
