import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';

@Component({
    selector: 'zarenta-login',
    templateUrl: './login.component.html',
    encapsulation: ViewEncapsulation.Emulated,
    styleUrls: [
        '../../assets/design/semantic-ui/components/button.min.css',
        '../../assets/design/semantic-ui/components/grid.min.css',
        '../../assets/design/semantic-ui/components/container.min.css',
        '../../assets/design/semantic-ui/components/segment.min.css',
        '../../assets/design/semantic-ui/components/form.min.css',
        '../../assets/design/semantic-ui/components/input.min.css',
        '../../assets/design/semantic-ui/components/checkbox.min.css',
        '../../assets/design/semantic-ui/components/message.min.css',
        './login.component.less'],
})
export class LoginComponent implements OnInit {
    reactiveLoginForm: FormGroup;
    http: HttpClient;
    submitted: boolean;
    userService: UserService;
    error: any;
    loading = false;

    constructor(@Inject(FormBuilder) private fb: FormBuilder, http: HttpClient, private router: Router, userService: UserService) {
        this.http = http;
        this.userService = userService;
    }

    get email() {
        return this.reactiveLoginForm.get('email');
    }

    get password() {
        return this.reactiveLoginForm.get('password');
    }

    ngOnInit() {
        this.initForm();
        this.userService.isAuthenticated().then(() => {
            this.router.navigate(['/']).catch(e => {
                console.error(e);
            });
        }).catch(e => {
            console.error(e);
        });

    }

    initForm() {
        this.reactiveLoginForm = new FormGroup({
            'email': new FormControl('', [
                Validators.required,
                Validators.email,
            ]),
            'password': new FormControl('', Validators.required),
        });
    }

    onSubmit() {
        this.loading = true;
        this.submitted = true;
        this.error = false;
        if (this.reactiveLoginForm.valid) {
            this.userService.login(this.reactiveLoginForm.getRawValue()).then(() => {
                this.router.navigate(['/']).catch(e => {
                    console.error(e);
                });
                this.loading = false;
            }).catch(err => {
                this.loading = false;
                this.error = err.error;
                console.log(err);
            });
        }
    }

}
