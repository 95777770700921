import { Component, EventEmitter, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'zarenta-action',
    templateUrl: './action.component.html',
    styleUrls: [
        './action.component.less',
        '../../assets/design/semantic-ui/components/button.min.css',
    ],
    encapsulation: ViewEncapsulation.None,
})

export class ActionComponent {
    @Input() public actions: {
        name: string,
        class: string,
        link: string,
    }[];
    @Input() public events: {
        event: EventEmitter<any>,
        name: string,
        class: string
    }[];
}
