import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUser } from '../_models/user.model';
import { UserService } from '../_services/user.service';

@Component({
    selector: 'zarenta-app-menu',
    templateUrl: './app-menu.component.html',
    styleUrls: ['../../assets/design/semantic-ui/components/image.min.css',
        './app-menu.component.less'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class AppMenuComponent implements OnInit {
    @Input() mobileVersion = false;
    currentUser: CurrentUser;

    constructor(private userService: UserService, private router: Router) {
    }

    ngOnInit() {
        this.currentUser = this.userService.current();
    }

    logout() {
        this.userService.logout();
        this.router.navigate(['/login']).catch(e => {
            console.error(e);
        });
    }

    routeToAdd(item: string) {
        this.router.navigateByUrl(item + '/add').catch(e => console.log(e));
    }

    routeToActivity(item: string) {
        this.router.navigateByUrl(item + '/add').catch(e => console.log(e));
    }
}
