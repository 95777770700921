import {Component, EventEmitter, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ClientService} from '../../_services/client.service';
import {Client} from '../../_models/client.model';
import {BaseDropdownComponent} from '../../common/dropdown/base-dropdown.component';

@Component({
    selector: 'zarenta-client-multi-dropdown',
    templateUrl: '../../common/dropdown/multi/base-multi-dropdown.component.html',
    styleUrls: [
        '../../../assets/design/semantic-ui/components/label.min.css'
    ],
    encapsulation: ViewEncapsulation.None
})
export class ClientMultiDropdownComponent extends BaseDropdownComponent<Client[], Client[]> implements OnInit {
    @ViewChild('selectItems', {static: true})
    selectItems;
    name = 'client';
    placeholder = 'Клиент';
    filterCondition: { name: 'activity'; ids: number[] } = null;

    @Input() event: EventEmitter<Client[]> = new EventEmitter<Client[]>();

    @Input()
    set filterBy(condition: { name: 'activity'; ids: number[] }) {
        this.filterCondition = condition;
        this.load().catch(console.error);
    }

    constructor(private clientService: ClientService) {
        super();
    }

    ngOnInit() {
        this.load().catch(console.error);
    }

    async load() {
        let showClients: Client[] = await this.clientService.find<Client>();
        if (
            this.filterCondition &&
            this.filterCondition.name &&
            this.filterCondition.ids &&
            this.filterCondition.ids.length > 0
        ) {
            showClients = showClients.filter(
                client =>
                    client[this.filterCondition.name] &&
                    this.filterCondition.ids.includes(+client[this.filterCondition.name].id),
            );
        }
        this.array = showClients;

        if (this.selected) {
            this.selected = this.array.filter(client => this.selected.find(_client => _client.id === client.id));
        }
    }

    save() {
        this.event.emit(this.selected);
        this.action.emit(this.selected);
    }
}
