import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../_services/user.service';

@Component({
    selector: 'zarenta-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: [
        '../../assets/design/semantic-ui/components/button.min.css',
        '../../assets/design/semantic-ui/components/grid.min.css',
        '../../assets/design/semantic-ui/components/container.min.css',
        '../../assets/design/semantic-ui/components/segment.min.css',
        '../../assets/design/semantic-ui/components/form.min.css',
        '../../assets/design/semantic-ui/components/checkbox.min.css',
        '../../assets/design/semantic-ui/components/message.min.css',
        '../login/login.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit {

    changePasswordForm: FormGroup;
    http: HttpClient;
    submitted: boolean;
    userService: UserService;
    error: any;
    email: string;
    key: string;
    password: string;
    token: string;

    constructor(@Inject(FormBuilder) private fb: FormBuilder,
                http: HttpClient,
                private router: Router,
                userService: UserService,
                private route: ActivatedRoute) {
        this.http = http;
        this.userService = userService;
    }

    get newPassword() {
        return this.changePasswordForm.get('newPassword');
    }

    get comparePassword() {
        return this.changePasswordForm.get('comparePassword');
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params.email) {
                this.email = params.email;
            }
            if (params.key) {
                this.key = params.key;
            }
        }, e => console.log(e));
        this.initForm();
        // -
        this.route.queryParams.subscribe(params => {
            // console.log(params);
            this.token = params['token'];
            // console.log(this.token);
        });
        // -
    }

    initForm() {
        this.changePasswordForm = new FormGroup({
            'newPassword': new FormControl('', [
                Validators.required
            ]),
            'comparePassword': new FormControl('', [
                Validators.required
            ])
        });
    }

    onSubmit() {
        if (this.changePasswordForm.valid) {
            this.password = this.newPassword.value;
            // this.userService.resetPassword(this.email, this.key, this.password, this.token).subscribe((s) => {
            this.userService.changePassword(this.password, this.token).subscribe((s) => {
                this.router.navigate(['/login']).catch(e => {
                    this.error = e;
                });
            }, e => {
                this.error = e.error;
            });
        }
    }
}
