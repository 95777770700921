<div class="main-bg">
    <div class="ui container">
        <div class="ui stackable doubling grid">
            <div class="ui five wide column"></div>
            <div class="ui six wide column login-container">
                <div class="login-form">
                    <form (ngSubmit)="onSubmit()" [formGroup]="changePasswordForm" class="ui form">
                        <div class="field">
                            <label class="fw-bold">Введите новый пароль</label>
                            <input class="form-control" formControlName="newPassword" required type="password">
                            <div *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched) && submitted"
                                 class="alert alert-danger">
                                <div *ngIf="newPassword.errors['required']">
                                    Введите пароль
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label class="fw-bold">Введите повторно</label>
                            <input class="form-control" formControlName="comparePassword" required type="password">
                            <div *ngIf="comparePassword.invalid && (comparePassword.dirty || comparePassword.touched) && submitted"
                                 class="alert alert-danger">
                                <div *ngIf="comparePassword.errors['required']">
                                    Введите пароль
                                </div>
                            </div>
                        </div>
                        <div *ngIf="error" class="ui negative message">
                            <div *ngIf="error.type" class="header">{{error.type}}</div>
                            <div *ngIf="error.action" class="content">
                                {{error.action}}
                            </div>
                            <div *ngIf="!error.type && !error.action" class="content">
                                Ошибка обновления пароля:<br/>
                                {{error.message.message[0].constraints.minLength}}
                            </div>
                        </div>
                        <button [ngClass]="{'disabled': newPassword.value === '' && comparePassword.value === '' || newPassword.value != comparePassword.value}"
                                class="ui fluid primary button m20" type="submit">
                            Сохранить
                        </button>
                        <br/>
                        <a [routerLink]="['/login']" class="small w-100 m20 d-block" style="color: #3e4887; font-size: 12px;">
                            Вернутся на главную
                        </a>
                    </form>
                </div>
            </div>
            <div class="ui sixteen wide column">
                <zarenta-app-footer [class]="'login-footer'"></zarenta-app-footer>
            </div>
        </div>
    </div>
</div>
