
        <div class="ui breadcrumb" *ngIf="breadcrumbs && breadcrumbs.length > 0">
            <ng-container *ngFor="let breadcrumb of breadcrumbs; last as isLast;">
                <h2 *ngIf="isLast" [innerText]="breadcrumb.label"></h2>
            </ng-container>
            <ng-container *ngFor="let breadcrumb of breadcrumbs; last as isLast; first as isFirst;">
                <a class="section" style="text-transform: uppercase;" *ngIf="!isLast" [routerLink]="[breadcrumb.url]" [innerText]="breadcrumb.label"></a>
                <div class="divider" *ngIf="!isLast && !isFirst"> /</div>
            </ng-container>
        </div>
    