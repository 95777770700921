import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuiModule } from 'ng2-semantic-ui';
import { MallDropdownComponent } from 'src/app/shared/single-dropdown/mall-dropdown.component';
import { FileSizePipe } from '../_pipes/file-size.pipe';
import { FileTypePipe } from '../_pipes/file-type.pipe';
import { FilterPipe } from '../_pipes/filter.pipe';
import { HighlightPipe } from '../_pipes/highlight.pipe';
import { MomentPipe } from '../_pipes/moment.pipe';
import { OrderByColumnPipe } from '../_pipes/order-by-column.pipe';
import { OrderByDatePipe } from '../_pipes/order-by-date.pipe';
import { PhonePipe } from '../_pipes/phone.pipe';
import { SafeHtmlPipe } from '../_pipes/safe-html.pipe';
import { SecurePipe } from '../_pipes/secure.pipe';
import { TruncatePipe } from '../_pipes/truncate.pipe';
import { UrlHelperPipe } from '../_pipes/url-helper.pipe';
import { BaseDropdownComponent } from '../common/dropdown/base-dropdown.component';
import { DaterangepickerComponent } from './daterangepicker/daterangepicker.component';
import { MessageComponent } from './message/message.component';
import { ActivityMultiDropdownComponent } from './multi-dropdown/activity-multi-dropdown.component';
import { BuildingMultiDropdownComponent } from './multi-dropdown/building-multi-dropdown.component';
import { ClientMultiDropdownComponent } from './multi-dropdown/client-multi-dropdown.component';
import { FloorMultiDropdownComponent } from './multi-dropdown/floor-multi-dropdown.component';
import { OfdMultiDropdownComponent } from './multi-dropdown/ofd-multi-dropdown.component';
import {OperationTypeMultiDropdownComponent} from './multi-dropdown/operationType-multi-dropdown';
import { RoomMultiDropdownComponent } from './multi-dropdown/room-multi-dropdown.component';
import { UserMultiDropdownComponent } from './multi-dropdown/user-multi-dropdown.component';
import { ZoneMultiDropdownComponent } from './multi-dropdown/zone-multi-dropdown.component';
import { ActivityDropdownComponent } from './single-dropdown/activity-dropdown.component';
import { BuildingDropdownComponent } from './single-dropdown/building-dropdown.component';
import { ClientDropdownComponent } from './single-dropdown/client-dropdown.component';
import { FloorDropdownComponent } from './single-dropdown/floor-dropdown.component';
import { OfdDropdownComponent } from './single-dropdown/ofd-dropdown.component';
import { RoomDropdownComponent } from './single-dropdown/room-dropdown.component';
import { ZoneDropdownComponent } from './single-dropdown/zone-dropdown.component';
import { ReportSearchButtonComponent } from './report-search-button/report-search-button.component';

@NgModule({
    declarations: [
        DaterangepickerComponent,
        FloorMultiDropdownComponent,
        ZoneMultiDropdownComponent,
        ClientMultiDropdownComponent,
        OfdMultiDropdownComponent,
        RoomMultiDropdownComponent,
        BuildingMultiDropdownComponent,
        OperationTypeMultiDropdownComponent,
        OrderByColumnPipe,
        SecurePipe,
        PhonePipe,
        FileSizePipe,
        FileTypePipe,
        HighlightPipe,
        OrderByDatePipe,
        SafeHtmlPipe,
        TruncatePipe,
        UrlHelperPipe,
        FilterPipe,
        MomentPipe,
        ClientDropdownComponent,
        FloorDropdownComponent,
        ZoneDropdownComponent,
        BuildingDropdownComponent,
        RoomDropdownComponent,
        OfdDropdownComponent,
        BaseDropdownComponent,
        UserMultiDropdownComponent,
        ActivityDropdownComponent,
        ActivityMultiDropdownComponent,
        MessageComponent,
        MallDropdownComponent,
        ReportSearchButtonComponent,
    ],
    imports: [CommonModule, SuiModule, FormsModule, ReactiveFormsModule],
    exports: [
        MessageComponent,
        DaterangepickerComponent,
        FloorMultiDropdownComponent,
        ZoneMultiDropdownComponent,
        ClientMultiDropdownComponent,
        OfdMultiDropdownComponent,
        RoomMultiDropdownComponent,
        BuildingMultiDropdownComponent,
        OperationTypeMultiDropdownComponent,
        OrderByColumnPipe,
        FileSizePipe,
        FileTypePipe,
        HighlightPipe,
        OrderByDatePipe,
        SafeHtmlPipe,
        TruncatePipe,
        UrlHelperPipe,
        SecurePipe,
        PhonePipe,
        FilterPipe,
        MomentPipe,
        ClientDropdownComponent,
        FloorDropdownComponent,
        ZoneDropdownComponent,
        BuildingDropdownComponent,
        RoomDropdownComponent,
        OfdDropdownComponent,
        BaseDropdownComponent,
        UserMultiDropdownComponent,
        ActivityDropdownComponent,
        ActivityMultiDropdownComponent,
        MallDropdownComponent,
        ReportSearchButtonComponent,
    ],
})
export class SharedModule {
}
